// src/LanguageContext.js
import React, { createContext, useContext, useState } from 'react';
import translations from './translation.js';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const t = (key) => {
    return translations[language][key] || key;
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, t, handleLanguageChange }}>
      {children}
    </LanguageContext.Provider>
  );
};