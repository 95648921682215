// src/translations.js

const translations = {
  en: {
    // Navigation
    services: "Services",
    about: "About",
    contact: "Contact",
    
    // Hero
    heroTitle1: "Your Way",
    heroTitle2: "Across Europe",
    heroDescription: "From our headquarters in Sofia, Bulgaria, we connect businesses across Europe with advanced logistics solutions, ensuring on-time deliveries, optimized routes, and a commitment to excellence.",
    contactUs: "Contact Us",
    
    // Services Section
    ourServices: "Our Services",
    service1Title: "European Road Transport",
    service1Description: "Efficient and reliable freight solutions across all European countries with punctual delivery schedules and real-time tracking",
    service2Title: "Cross-Border Logistics",
    service2Description: "Seamless transportation services throughout Europe with expert handling of customs documentation and international regulations",
    service3Title: "Bulgarian Hub Services",
    service3Description: "Strategically located facilities in Sofia offering convenient transit solutions and temporary storage for European freight routes",
    
    // About Section
    aboutUs: "About Us",
    aboutTitle: "Your Reliable European Transport Partner",
    aboutDescription: "Europa Transport specializes in freight transportation across Europe from our headquarters in Sofia, Bulgaria. Strategically positioned at the crossroads of Eastern and Western Europe, we deliver efficient logistics solutions with multilingual support in Bulgarian and English.",
    feature1: "Fully insured and licensed transport operations across Europe",
    feature2: "Optimized transit routes and guaranteed delivery schedules",
    feature3: "Commitment to reliability and on-time delivery performance",
    yearsExperience: "Years of Specialized Transport Experience",
    satisfactionRate: "Client Satisfaction Rate",
    europeanCoverage: "European Coverage",
    coverageDescription: "Operating from our Bulgarian base in Sofia, we connect businesses across all European countries with reliable and efficient freight transportation services.",
    
    // Contact Section
    getInTouch: "Get in Touch",
    contactDescription: "We're here to help! Whether you have a question, need more information, or just want to say hello, feel free to reach out. You can contact us by phone, send us an email, or visit our office. We'd love to meet you! Our team is always ready to assist you and ensure you get the support you need. Don't hesitate to get in touch!",
    phone: "Phone",
    address: "Address",
    email: "Email",
    
    // Footer
    copyright: "© 2025 Europa Transport. All rights reserved.",
    languages: "Languages: Bulgarian and English",
    registeredOffice: "Registered Office: 81 Buxton Brothers Blvd. Office 3 Floor 1 (1618), Sofia, Bulgaria",
    
    // Privacy and Cookie Policy
    privacyPolicy: "Privacy Policy",
    cookiePolicy: "Cookie Policy"
  },
  
  bg: {
    // Navigation
    services: "Услуги",
    about: "За нас",
    contact: "Контакти",
    
    // Hero
    heroTitle1: "Вашият път",
    heroTitle2: "из Европа",
    heroDescription: "От нашата централа в София, България, свързваме бизнеси в цяла Европа с напреднали логистични решения, осигурявайки навременни доставки, оптимизирани маршрути и ангажимент към съвършенство.",
    contactUs: "Свържете се с нас",
    
    // Services Section
    ourServices: "Нашите услуги",
    service1Title: "Европейски сухопътен транспорт",
    service1Description: "Ефективни и надеждни товарни решения в цяла Европа с точни графици на доставка и проследяване в реално време",
    service2Title: "Трансгранична логистика",
    service2Description: "Безпроблемни транспортни услуги в цяла Европа с експертно обработване на митнически документи и международни разпоредби",
    service3Title: "Български хъб услуги",
    service3Description: "Стратегически разположени съоръжения в София, предлагащи удобни транзитни решения и временно съхранение за европейски товарни маршрути",
    
    // About Section
    aboutUs: "За нас",
    aboutTitle: "Вашият надежден европейски транспортен партньор",
    aboutDescription: "Europa Transport специализира в товарен транспорт в цяла Европа от нашата централа в София, България. Стратегически позиционирани на кръстопътя между Източна и Западна Европа, ние доставяме ефективни логистични решения с многоезична поддръжка на български и английски.",
    feature1: "Напълно застраховани и лицензирани транспортни операции в цяла Европа",
    feature2: "Оптимизирани транзитни маршрути и гарантирани графици на доставка",
    feature3: "Ангажимент към надеждност и навременно изпълнение на доставките",
    yearsExperience: "Години специализиран транспортен опит",
    satisfactionRate: "Процент удовлетвореност на клиентите",
    europeanCoverage: "Европейско покритие",
    coverageDescription: "Работейки от нашата българска база в София, свързваме бизнеси в цяла Европа с надеждни и ефективни услуги за товарен транспорт.",
    
    // Contact Section
    getInTouch: "Свържете се с нас",
    contactDescription: "Ние сме тук, за да помогнем! Независимо дали имате въпрос, нуждаете се от повече информация или просто искате да поздравите, не се колебайте да се свържете. Можете да се свържете с нас по телефона, да ни изпратите имейл или да посетите нашия офис. Бихме се радвали да се запознаем! Нашият екип винаги е готов да ви помогне и да гарантира, че получавате необходимата подкрепа. Не се колебайте да се свържете!",
    phone: "Телефон",
    address: "Адрес",
    email: "Имейл",
    
    // Footer
    copyright: "© 2025 Europa Transport. Всички права запазени.",
    languages: "Езици: Български и английски",
    registeredOffice: "Регистриран офис: бул. Buxton Brothers 81, Офис 3, Етаж 1 (1618), София, България",
    
    // Privacy and Cookie Policy
    privacyPolicy: "Политика за поверителност",
    cookiePolicy: "Политика за бисквитките"
  }
};

export default translations;