// src/components/Services.js
import React from 'react';
import { Truck, Globe, MapPin } from 'lucide-react';
import { useLanguage } from '../LanguageContext.js';

const Services = () => {
  const { t } = useLanguage();
  
  const services = [
    {
      icon: <Truck className="w-8 h-8" />,
      titleKey: 'service1Title',
      descriptionKey: 'service1Description',
      image: "/images/2.jpg"
    },
    {
      icon: <Globe className="w-8 h-8" />,
      titleKey: 'service2Title',
      descriptionKey: 'service2Description',
      image: "/images/5.jpg"
    },
    {
      icon: <MapPin className="w-8 h-8" />,
      titleKey: 'service3Title',
      descriptionKey: 'service3Description',
      image: "/images/7.jpg"
    }
  ];

  return (
    <section id="services" className="py-16 px-4 relative overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute top-20 left-0 w-72 h-72 bg-blue-500/10 rounded-full blur-3xl"></div>
        <div className="absolute bottom-20 right-0 w-72 h-72 bg-cyan-500/10 rounded-full blur-3xl"></div>
      </div>

      <div className="max-w-7xl mx-auto relative">
        <h2 className="text-4xl font-bold font-heading text-center mb-16 bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent">
          {t('ourServices')}
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="group relative hover:translate-y-1 transition-transform duration-300">
              {/* Card background with hover effect */}
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 to-cyan-500/0 group-hover:from-blue-500/10 group-hover:to-cyan-500/10 rounded-2xl transition-colors duration-500"></div>
              
              {/* Main content */}
              <div className="relative p-8 font-heading rounded-2xl bg-white/5 border border-white/10 backdrop-blur-sm shadow-lg">
                {/* Image section */}
                <div className="mb-6 rounded-xl overflow-hidden">
                  <img 
                    src={service.image} 
                    alt={t(service.titleKey)}
                    className="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-500"
                  />
                </div>

                {/* Icon */}
                <div className="bg-gradient-to-r from-blue-500 to-cyan-500 p-3 rounded-lg w-fit mb-6">
                  {service.icon}
                </div>

                {/* Text content */}
                <h3 className="text-xl font-heading font-semibold mb-4">{t(service.titleKey)}</h3>
                <p className="text-gray-400">{t(service.descriptionKey)}</p>

                {/* Hover overlay */}
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 to-cyan-500/0 group-hover:from-blue-500/5 group-hover:to-cyan-500/5 rounded-2xl transition-colors duration-500 pointer-events-none"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;