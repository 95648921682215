// src/components/Footer.js
import React from 'react';
import { useLanguage } from '../LanguageContext.js';

const Footer = () => {
  const { t, language } = useLanguage();
  
  return (
    <footer className="py-8 px-4 border-t border-white/10">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <p className="text-gray-400 font-heading">{t('copyright')}</p>
          <div className="mt-4 space-y-2 font-heading text-gray-400">
            <p>{t('languages')}</p>
            <p>{t('registeredOffice')}</p>
          </div>
          
          {/* Privacy and Cookie Policy Links */}
          <div className="mt-6 flex justify-center space-x-4">
            <a 
              href="https://www.iubenda.com/privacy-policy/56197863" 
              className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe text-gray-400 hover:text-blue-400 transition-colors" 
              title={t('privacyPolicy')}
            >
              {t('privacyPolicy')}
            </a>
            <a 
              href="https://www.iubenda.com/privacy-policy/56197863/cookie-policy" 
              className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe text-gray-400 hover:text-blue-400 transition-colors" 
              title={t('cookiePolicy')}
            >
              {t('cookiePolicy')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;