// src/components/Hero.js
import React from 'react';
import { ArrowRight } from 'lucide-react';
import { useLanguage } from '../LanguageContext.js';

const Hero = () => {
  const { t } = useLanguage();

  return (
    <header className="pt-32 pb-16 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h1 className="text-6xl font-bold mb-6 font-heading bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent">
              <span>{t('heroTitle1')}</span>
              <br />
              <span>{t('heroTitle2')}</span>
            </h1>
            <p className="text-xl mb-8 font-heading text-gray-300">
              {t('heroDescription')}
            </p>
            <a 
              href="#contact"
              className="group inline-flex font-heading bg-gradient-to-r from-blue-500 to-cyan-500 px-8 py-4 rounded-full font-semibold items-center gap-2 hover:shadow-lg hover:shadow-blue-500/20 transition-all"
            >
              {t('contactUs')}
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </a>
          </div>
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-2xl blur-3xl opacity-20"></div>
            <div className="relative z-10 rounded-2xl overflow-hidden shadow-xl shadow-blue-500/20">
              <img 
                src="/images/1.jpg" 
                alt="Transport Services"
                className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero;