// src/components/Navigation.js
import React from 'react';
import { Menu, X } from 'lucide-react';
import { useLanguage } from '../LanguageContext.js';

const Navigation = ({ isMenuOpen, setIsMenuOpen }) => {
  const { language, handleLanguageChange, t } = useLanguage();

  return (
    <nav className="fixed w-full z-50 bg-white/10 backdrop-blur-lg border-b border-white/20">
      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <div>
            <img src="/images/logo.png" alt="Europa Transport" className="h-10" />
          </div>

          <div className="flex items-center space-x-8">
            {/* Desktop menu */}
            <div className="hidden md:flex space-x-8">
              <a href="#services" className="hover:text-blue-400 font-heading transition-colors font-medium">{t('services')}</a>
              <a href="#about" className="hover:text-blue-400 font-heading transition-colors font-medium">{t('about')}</a>
              <a href="#contact" className="hover:text-blue-400 font-heading transition-colors font-medium">{t('contact')}</a>
            </div>

            {/* Language Selector - Desktop */}
            <div className="hidden md:flex rounded-lg bg-white/5 p-1">
              <button 
                onClick={() => handleLanguageChange('en')}
                className={`px-3 py-1 rounded-md text-sm font-medium transition-all ${
                  language === 'en' 
                    ? 'bg-gradient-to-r from-blue-500 to-cyan-500 text-white' 
                    : 'text-gray-300 hover:text-white'
                }`}
              >
                English
              </button>
              <button 
                onClick={() => handleLanguageChange('bg')}
                className={`px-3 py-1 font-heading rounded-md text-sm font-medium transition-all ${
                  language === 'bg' 
                    ? 'bg-gradient-to-r from-blue-500 to-cyan-500 text-white' 
                    : 'text-gray-300 hover:text-white'
                }`}
              >
                български
              </button>
            </div>
          </div>

          {/* Mobile menu button and language */}
          <div className="flex items-center md:hidden">
            {/* Language Selector - Mobile */}
            <div className="flex rounded-lg bg-white/5 p-1 mr-4">
              <button 
                onClick={() => handleLanguageChange('en')}
                className={`px-2 py-1 font-heading rounded-md text-sm font-medium transition-all ${
                  language === 'en' 
                    ? 'bg-gradient-to-r from-blue-500 to-cyan-500 text-white' 
                    : 'text-gray-300 hover:text-white'
                }`}
              >
                EN
              </button>
              <button 
                onClick={() => handleLanguageChange('bg')}
                className={`px-2 py-1 rounded-md text-sm font-medium transition-all ${
                  language === 'bg' 
                    ? 'bg-gradient-to-r from-blue-500 to-cyan-500 text-white' 
                    : 'text-gray-300 hover:text-white'
                }`}
              >
                BG
              </button>
            </div>
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-gray-300 hover:text-white">
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white/10 backdrop-blur-lg">
          <div className="px-4 py-2 space-y-2">
            <a href="#services" className="block py-2 font-heading text-gray-300 hover:text-white font-medium">{t('services')}</a>
            <a href="#about" className="block py-2 font-heading text-gray-300 hover:text-white font-medium">{t('about')}</a>
            <a href="#contact" className="block py-2 font-heading text-gray-300 hover:text-white font-medium">{t('contact')}</a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;