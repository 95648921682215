// src/App.js
import React, { useState } from 'react';
import { LanguageProvider } from './LanguageContext.js';
import Navigation from './components/Navigation.js';
import Hero from './components/Hero.js';
import Services from './components/Services.js';
import AboutSection from './components/AboutSection.js';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';
import CookieSolution from './components/CookieSolution.js';

const TransportWebsite = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <LanguageProvider>
      <div className="min-h-screen bg-gradient-to-br from-slate-900 via-blue-900 to-slate-900 text-white font-sans">
        {/* Il componente CookieSolution si occupa di caricare 
            tutti gli script necessari per Iubenda */}
        <CookieSolution />
        
        <Navigation isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <Hero />
        <Services />
        <AboutSection />
        <Contact />
        <Footer />
      </div>
    </LanguageProvider>
  );
};

export default TransportWebsite;