// src/components/Contact.js
import React from 'react';
import { Phone, Mail, MapPin, ArrowUpRight } from 'lucide-react';
import { useLanguage } from '../LanguageContext.js';

const Contact = () => {
  const { t } = useLanguage();
  
  return (
    <section id="contact" className="py-24 px-4 relative overflow-hidden">
      {/* Decorative background elements */}
      <div className="absolute inset-0">
        <div className="absolute top-1/4 left-0 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl"></div>
        <div className="absolute bottom-1/4 right-0 w-96 h-96 bg-cyan-500/10 rounded-full blur-3xl"></div>
      </div>

      <div className="max-w-7xl mx-auto relative">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold font-heading bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent mb-4">
            {t('getInTouch')}
          </h2>
          <p className="text-gray-400 font-heading max-w-2xl mx-auto">
            {t('contactDescription')}
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {/* Phone Card */}
          <a 
            href="tel:+359876400693"
            className="group bg-white/5 rounded-3xl border border-white/10 backdrop-blur-sm hover:bg-white/10 transition-all relative overflow-hidden flex"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 to-cyan-500/0 group-hover:from-blue-500/10 group-hover:to-cyan-500/10 transition-colors duration-500"></div>
            
            <div className="relative flex flex-col items-center text-center p-8 w-full justify-center">
              <div className="flex items-center justify-center w-full mb-6">
                <div className="bg-gradient-to-r from-blue-500 to-cyan-500 p-3 rounded-xl">
                  <Phone className="w-6 h-6" />
                </div>
              </div>
              
              <h3 className="text-xl font-semibold mb-2">{t('phone')}</h3>
              <p className="text-gray-400 font-heading group-hover:text-white transition-colors">+359 876 400 693</p>
              
              <ArrowUpRight className="w-5 h-5 text-gray-400 group-hover:text-white transition-colors absolute top-8 right-8" />
            </div>
          </a>

          {/* Address Card */}
          <div className="bg-white/5 rounded-3xl border border-white/10 backdrop-blur-sm relative overflow-hidden flex">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 to-cyan-500/5"></div>
            
            <div className="relative flex flex-col items-center text-center p-8 w-full justify-center">
              <div className="flex items-center justify-center w-full mb-6">
                <div className="bg-gradient-to-r from-blue-500 to-cyan-500 p-3 rounded-xl">
                  <MapPin className="w-6 h-6" />
                </div>
              </div>
              
              <h3 className="text-xl font-semibold mb-2">{t('address')}</h3>
              <p className="font-heading text-gray-400">
                81 Buxton Brothers Blvd.<br />
                Office 3, Floor 1 (1618)<br />
                Sofia, Bulgaria
              </p>
            </div>
          </div>

          {/* Email Card */}
          <a 
            href="mailto:info@europa-transport.eu"
            className="group bg-white/5 rounded-3xl border border-white/10 backdrop-blur-sm hover:bg-white/10 transition-all relative overflow-hidden flex"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 to-cyan-500/0 group-hover:from-blue-500/10 group-hover:to-cyan-500/10 transition-colors duration-500"></div>
            
            <div className="relative flex flex-col items-center text-center p-8 w-full justify-center">
              <div className="flex items-center justify-center w-full mb-6">
                <div className="bg-gradient-to-r from-blue-500 to-cyan-500 p-3 rounded-xl">
                  <Mail className="w-6 h-6" />
                </div>
              </div>
              
              <h3 className="text-xl font-semibold mb-2">{t('email')}</h3>
              <p className="text-gray-400 font-heading group-hover:text-white transition-colors">info@europa-transport.eu</p>
              
              <ArrowUpRight className="w-5 h-5 text-gray-400 group-hover:text-white transition-colors absolute top-8 right-8" />
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;