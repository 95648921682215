// src/components/AboutSection.js
import React from 'react';
import { Shield, Clock, Award, TrendingUp, Users, MapPin } from 'lucide-react';
import { useLanguage } from '../LanguageContext.js';

const AboutSection = () => {
  const { t } = useLanguage();
  
  return (
    <section id="about" className="py-20 px-4 bg-white/5 relative overflow-hidden">
      {/* Enhanced background decorative elements */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-72 h-72 bg-blue-500 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-72 h-72 bg-cyan-500 rounded-full blur-3xl"></div>
        <div className="absolute top-1/2 right-1/4 w-48 h-48 bg-indigo-500 rounded-full blur-3xl"></div>
      </div>

      <div className="max-w-7xl mx-auto relative">
        <h2 className="text-4xl font-bold font-heading text-center mb-16 bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent">
          {t('aboutUs')}
        </h2>
        
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <h3 className="text-2xl font-heading font-semibold mb-6">{t('aboutTitle')}</h3>
            <p className="text-gray-300 font-heading mb-6">
              {t('aboutDescription')}
            </p>
            
            {/* Main image with enhanced shadow */}
            <div className="rounded-2xl overflow-hidden mb-8 shadow-lg shadow-blue-500/20 transform transition-all duration-500 hover:shadow-blue-400/30">
              <img 
                src="/images/4.jpg" 
                alt="Europa Transport Fleet" 
                className="w-full h-auto object-cover transform hover:scale-105 transition-transform duration-500"
              />
            </div>

            <div className="space-y-5">
              {[
                { icon: <Shield className="w-6 h-6" />, textKey: 'feature1' },
                { icon: <Clock className="w-6 h-6" />, textKey: 'feature2' },
                { icon: <Award className="w-6 h-6" />, textKey: 'feature3' }
              ].map((item, index) => (
                <div key={index} className="flex items-center gap-4 group">
                  <div className="text-blue-400 group-hover:text-cyan-400 transition-colors duration-300">
                    {item.icon}
                  </div>
                  <p className="text-gray-300 group-hover:text-gray-200 transition-colors duration-300">{t(item.textKey)}</p>
                </div>
              ))}
            </div>
          </div>
          
          <div>
            {/* Enhanced statistics grid */}
            <div className="grid grid-cols-2 gap-6">
              <div className="bg-white/10 p-6 rounded-2xl relative overflow-hidden group shadow-lg shadow-cyan-500/10">
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-cyan-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                <h4 className="text-5xl font-bold mb-2 relative">2+</h4>
                <p className="text-sm font-heading relative">{t('yearsExperience')}</p>
              </div>
              
              <div className="bg-gradient-to-br from-blue-600 to-cyan-500 p-6 rounded-2xl relative overflow-hidden group shadow-lg shadow-blue-500/20">
                <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                <h4 className="text-5xl font-bold mb-2 relative">100%</h4>
                <p className="text-sm font-heading relative">{t('satisfactionRate')}</p>
              </div>
            </div>
            
            {/* Enhanced coverage map section */}
            <div className="mt-6 bg-white/10 p-6 rounded-2xl relative overflow-hidden group shadow-lg shadow-blue-500/10">
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-cyan-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              <div className="flex items-center justify-center mb-3">
                <MapPin className="w-6 h-6 text-blue-400 mr-2" />
                <h4 className="text-3xl font-bold relative bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent">{t('europeanCoverage')}</h4>
              </div>
              <p className="text-gray-300 font-heading relative text-center">
                {t('coverageDescription')}
              </p>
            </div>

            {/* Additional image with enhanced effects */}
            <div className="mt-6 rounded-2xl overflow-hidden shadow-lg shadow-cyan-500/20 transform transition-all duration-500 hover:shadow-cyan-400/30">
              <img 
                src="/images/3.jpg" 
                alt="Our Facilities" 
                className="w-full h-auto object-cover transform hover:scale-105 transition-transform duration-500"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;