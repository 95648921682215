// src/components/CookieSolution.js
import React, { useEffect } from 'react';
import { useLanguage } from '../LanguageContext.js';

const CookieSolution = () => {
  const { language } = useLanguage();
  
  useEffect(() => {
    // Imposta la lingua corretta in base alla selezione dell'utente
    const currentLang = language === 'bg' ? 'bg' : 'en-GB';
    
    // Crea e aggiunge lo script di configurazione
    const configScript = document.createElement('script');
    configScript.type = 'text/javascript';
    configScript.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = {
        "siteId": 3941968,
        "cookiePolicyId": 56197863,
        "lang": "${currentLang}",
        "storage": {
          "useSiteId": true
        }
      };
    `;
    document.head.appendChild(configScript);
    
    // Crea e aggiunge lo script autoblocking
    const autoBlockingScript = document.createElement('script');
    autoBlockingScript.type = 'text/javascript';
    autoBlockingScript.src = 'https://cs.iubenda.com/autoblocking/3941968.js';
    document.head.appendChild(autoBlockingScript);
    
    // Crea e aggiunge lo script principale
    const mainScript = document.createElement('script');
    mainScript.type = 'text/javascript';
    mainScript.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
    mainScript.charset = 'UTF-8';
    mainScript.async = true;
    document.head.appendChild(mainScript);

    // Funzione di pulizia
    return () => {
      if (configScript.parentNode) {
        document.head.removeChild(configScript);
      }
      if (autoBlockingScript.parentNode) {
        document.head.removeChild(autoBlockingScript);
      }
      if (mainScript.parentNode) {
        document.head.removeChild(mainScript);
      }
    };
  }, [language]);

  return null; // Questo componente non renderizza elementi visibili
};

export default CookieSolution;